import { extendTheme } from '@chakra-ui/react';
import {
  BG_SECONDARY,
  BG_PRIMARY,
  BG_ALTERNATIVE,
  TEXT_PRIMARY,
  TEXT_SECONDARY,
  TEXT_ALTERNATIVE,
  BG_STEP_RSVP,
} from '@/constants/colors';
import { FONT_FAMILY } from '@/constants/default-props';

/**
 * override chackra-ui for personalize purpose
 * see the docs: https://chakra-ui.com/guides/using-fonts
 */
const theme = {
  fonts: {
    heading: FONT_FAMILY.heading,
    body: FONT_FAMILY.body,
    body_lv2: FONT_FAMILY.body_lv2,

    // Hard coded font for QR purposes
    jost: `'Jost', sans-serif;`,
  },
  fontSizes: {
    heading: '32px',
    body: '18px',
    body_lv2: '16px',
  },
  fontWeights: {
    heading: 300,
    body: 400,
    body_lv2: 700,
  },
  styles: {
    global: {
      ':root': {
        '--fontItalic-heading': '',
        '--fontItalic-body': 'italic',
        '--fontItalic-body_lv2': '',
      },
    },
  },
  colors: {
    bgPrimary: BG_PRIMARY,
    bgSecondary: BG_SECONDARY,
    bgAlternative: BG_ALTERNATIVE,
    bgStepRSVP: BG_STEP_RSVP,
    mainColorText: TEXT_PRIMARY,
    secondaryColorText: TEXT_SECONDARY,
    alternativeColorText: TEXT_ALTERNATIVE,
    theme: {
      bgPrimaryText: TEXT_PRIMARY,
      bgPrimary: BG_PRIMARY,
      bgSecondary: BG_SECONDARY,
      bgAlternative: BG_ALTERNATIVE,
    },
    button: {
      secondary: {
        500: BG_SECONDARY,
      },
      secondaryDark: {
        500: BG_ALTERNATIVE,
      },
    },
  },
  shadows: {
    outline: BG_ALTERNATIVE,
  },
  components: {
    Radio: {
      variants: {
        custom: {
          control: {
            w: '22px',
            h: '22px',
            backgroundColor: 'bgSecondary',
            borderColor: 'mainColorText',
            borderWidth: '1px',
            _checked: {
              bg: 'bgAlternative',
              borderColor: 'bgAlternative',
              color: 'white',
              _hover: {
                bg: 'bgAlternative',
                borderColor: 'bgAlternative',
                color: 'white',
              },
            },
          },
        },
      },
    },
  },
};

export default extendTheme(theme);
